import React, { useState, useRef, useEffect } from 'react';
import { Anchor, Button, Divider, Drawer, Layout } from 'antd';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { HeaderStyles, MobileStyle } from './styles';
import MenuIcon from '../../images/icons/menu.svg';
import iconBrandName from '../../images/icons/PAV.svg';
import { debounce } from '../../tools';

const MENU = [
  {
    title: 'Your veterinarians',
    href: '/veterinarians',
    key: 'yourVeterinarians',
  },
  {
    title: 'For veterinarians',
    href: '/registration-information',
    key: 'forVeterinarians',
  },
  {
    title: 'Contact',
    href: '/#contact',
    key: 'contact',
  },
];

interface HeaderProps {
  handleSetActiveKey: (key: string) => void;
  activeKey: string;
}

const Header: FC<HeaderProps> = ({ activeKey, handleSetActiveKey }) => {
  const navRef = useRef();
  const location = useLocation();
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const lastScrollY = window.scrollY;

    if (navRef.current) {
      navRef.current.checked = lastScrollY > 0;
    }
  };

  useEffect(() => {
    const debounceHandleScroll = debounce(handleScroll, 5);
    window.addEventListener('scroll', debounceHandleScroll, true);
    return () => {
      window.removeEventListener('scroll', debounceHandleScroll);
    };
  }, []);

  return (
    <Anchor>
      <HeaderStyles>
        <input
          id="headerTracker"
          type="checkbox"
          className="hiddenCheckbox"
          ref={navRef}
        />
        <Layout.Header className="mainHeader">
          <div className="row container header-container">
            <div className="brand-name">
              <a href="/#banner">
                <img src={iconBrandName} className="brand-name-img" />
              </a>
            </div>
            <div className="row desktopMenu">
              {location?.href && MENU.map((e) => (
                <a
                  className={`menuItem ${location?.href?.includes(e.href) ? 'active' : ''
                    }`}
                  key={e.title}
                  href={e.href}
                >
                  {e.title}
                </a>
              ))}
            </div>
            <img
              onClick={() => setVisible(true)}
              className="menuIcon"
              src={MenuIcon}
              alt=""
            />
          </div>
        </Layout.Header>
        <Drawer width="75%" visible={visible} onClose={() => setVisible(false)}>
          <MobileStyle>
            {location?.href && MENU.map((e) => (
              <div
                className={`menuItem ${location?.href?.includes(e.href) ? 'active' : ''
                  }`}
                key={e.title}
              >
                <a
                  onClick={() => {
                    setVisible(false);
                    setTimeout(() => {
                      document.querySelector(e.href).scrollIntoView({
                        behavior: 'smooth',
                      });
                    }, 50);
                  }}
                  key={e.title}
                  href={e.href}
                >
                  {e.title}
                </a>
              </div>
            ))}
          </MobileStyle>
        </Drawer>
      </HeaderStyles>
    </Anchor>
  );
};
Header.propTypes = {};

const query = graphql`
  query ownerUrl {
    site {
      siteMetadata {
        ownerSite
      }
    }
  }
`;

export default Header;

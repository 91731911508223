import { createGlobalStyle } from 'styled-components';
import bg from '../images/PAV_banner_backgroud.png';
import bg2 from '../images/PAV_banner_backgroud-2.png';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  div {
    scroll-behavior: smooth;
  }
  body {
    scroll-behavior: smooth;
      overflow-x: hidden;
      width:"100%";
      margin:auto;
      font-family: DM Sans;
      font-style: normal;
      color: #1B1B1B;
      padding:0px;
  }
  .ant-layout{
    max-width: 100vw;
  }

  
  * {
    font-family: DM Sans;
    letter-spacing: 0.6px;
  }
  
  b, strong {
    font-weight: 550;
  }
  a {
    color: #1B1B1B;
  }
  .title {
    line-height: 1em;
    &.pav-title {
      letter-spacing: 0;
    }
  }

  .t-bold-40px-50 {
    font: normal bold 40px/50px 'DM Sans' ;
    letter-spacing: 1px;
    color: #FFFFFF;
  }

  .t-normal-20px-36 {
    font: normal normal 20px/36px 'DM Sans' ;
    color: #F7F7F7;
  }

  .t-bold-18px-30 {
    font: normal bold 18px/30px 'DM Sans' ;
    letter-spacing: 1px;
    color: #F7F7F7;
  }

  .t-normal-16px-24 {
    font: normal normal 16px/24px 'DM Sans';
    letter-spacing: 0.2px;
    color: #F7F7F7;
  }

  .t-normal-14px-30 {
    font: normal normal 14px/30px 'DM Sans';
    letter-spacing: 1px;
    color: #737C80;
  }

  .mg-bottom-20 {
    margin-bottom: 20px;
  }

  .mg-top-24 {
    margin-top: 24px;
  }

  .pd-top-10 {
    padding-top: 10px;
  }

  input.ant-input, textarea.ant-input {
    // border: 1px solid #737C80;
    box-sizing: border-box;
    border-radius: 16px !important;
    height: 44px !important;
  }

  .txt-center {
    text-align:center;
  }

  .description {
    font-size: 20px;
  }

  button {
    font-family: DM Sans;
  }


  .ant-btn {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  }
  .ant-drawer-header {
    border: none;
  }   
  .ant-drawer-title {
    text-align:center;
  }

  .pav-title {
    font-family: DM Sans;
    font-weight: bold;
    font-size: 40px;
    font-style: normal;
    * {
      font-family: DM Sans;
    }
  }

  .description, .feature-text, .title {
    p {
      margin-bottom: 0;
    }
  }
  .feature-text {
    padding-bottom: 1em;
  }
  .sub-title {
    p {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    iframe {
      height: 400px;
      width: 100%;
    }
  }

  button {
    font-family: DM Sans;
  }

  .title, .sub-title {
    color: #1B1B1B;
  }

  .sub-title {
    font-weight: 400;
  }

  .zesttee-text {
    color: ${theme.palette.primary};
  }

  .title-underline {
    border-bottom: 3px solid ${theme.palette.primary};
  }

  .container {
    width: 100%;
    padding: 24px 8px 24px 40px;
    @media only screen and (max-width: 768px) {
      padding: 24px 40px;     
    }
    @media only screen and (max-width: 576px) {
      padding: 24px;
    }
  }

  .d-flex {
    display: flex;
    align-items:center;
    justify-content:flex-start;
  }
  .ant-input {
    border-radius: 5px;
    height: 43px
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .radio-group{
    width: 100%;
    .radio {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
      justify-content: space-between;
    }
  }
  .bold {
    font-weight: 550;
  }

  #checkout-3ds-modal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: white;
    position: fixed;
  }

  .ant-drawer-header {
    .ant-drawer-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
    }
  }

  .ant-drawer-body {
    padding: 10px 0;
    .drawer-title-item {
      padding: 10px 24px;
      font-weight: 700;
    }
    .menu-item {
      margin: 0;
      padding: 10px 24px;
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: DM Sans, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .brand-name {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 550;
    font-size: 28px;
    line-height: 35px;
    /* identical to box height */
    color: #1B1B1B;
  }
  input:-internal-autofill-selected {
    background-color: #fff !important;
  }
  .ant-layout-header {
    padding: 0px;
    height: auto;
  }
  .footer {
    background-color: #242e33;
    padding: 100px 120px;

    .img-download-group {
      display: flex;
      padding: 24px 0px;
      .img-download-item {
        margin-right: 12px;
      }
    }
    .ic-social-group {
      .ic-social {
        margin-right: 23px;
      }
    }
    @media only screen and (max-width: 1024px) {
      .img-download-item {
        margin: 0 6px;
      }
      padding: 100px 40px 60px 40px;
      .left-content {
        margin-bottom: 66px;
        text-align: center;
        .title-left-content {
          font-weight: bold;
          font-size: 40px;
          line-height: 50px;
          letter-spacing: 1px;
          text-align: center;
        }
        .img-download-group {
          justify-content: center;
        }
      }
      .ant-row {
        .txt-copy {
          font-weight: normal;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 1px;

          margin-top: 14px;
        }

        .title-content {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 1px;
        }
        .description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          padding: 10px 0px;
        }
      }
    }
    @media only screen and (max-width: 576px) {
      padding: 8px 24px 60px 24px;
      .left-content {
        margin-bottom: 37px;
        text-align: center;
        .img-download-group {
          justify-content: center;
        }
      }

      .ant-row {
        .txt-copy {
          font-weight: normal;
          font-size: 10px;
          line-height: 30px;
          letter-spacing: 1px;

          margin-top: 24px;
        }
        .title-left-content {
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          letter-spacing: -0.25px;
        }
        .title-content {
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 1px;

          padding-top: 14px;
        }
        .description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;

          padding: 10px 0px;
        }
      }
      .footer-contact{
        justify-content:center;
      }
    }
  }
  #banner{
    background-image: url(${bg});
    background-size: cover;
    background-position: right bottom;
    background-size: 100%;
    background-repeat: no-repeat;
   //  min-height:420px;
   }

  #vetProfile {
    padding: 84px 0px;
    .ant-spin {
      display: flex;
      justify-content: center;
    }
    .personal-content {
      background: #eaf7fc;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 50px 0px;
  
      .background-img {
        width: 220px;
        background: #fff;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-bottom: 15px;
      }
  
      .vet-img {
        width: 200px;
        height: 200px;
        border-radius: 100%;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #29ABE2;
  
        .vet-icon {
          width: 100px;
          height: auto;
        }
      }
  
      .name {
        font-family: DM Sans;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
      }
  
      .title {
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        line-height: 28px;
        text-align: center;
        color: #29abe2;
      }
    }
  
    .vet-section {
      padding: 60px 90px;
      max-width: 1000px;
      margin: auto;
  
      .main-title {
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }
  
      .sub-title {
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.25px;
      }
  
      .desc {
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: #36464C;
      }
  
      .vet-feature {
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: #29ABE2;
      }
    }
  }
 
  .sub-forvets {
    @media only screen and (max-width: 1200px) {
      padding: 60px 100px 0px 100px!important;
    }
    @media only screen and (max-width: 992px) {
      padding: 32px 48px 0px 48px !important;
    }
    @media only screen and (max-width: 576px) {
      padding: 32px 24px 0px 24px !important;
    }    
  }
  // @media only screen and (max-width: 1200px) {
  //   #banner{
  //      min-height: 50px;
  //   }
  // }
  @media only screen and (max-width: 480px) {
  #banner{
   min-height: 716px;
   background-image: url(${bg2});
  //  background-size: cover;
  //  background-position: right bottom;
  //    background-repeat: no-repeat;
  // background-size: 100%;
  }
  }
`;

export default GlobalStyle;

import styled from 'styled-components';

export const CMSWrapper = styled.div`
  margin: 90px auto;
  width: 100%;
  max-width: 1000px;
  @media only screen and (max-width: 1024px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 576px) {
    padding: 0 5px;
  }
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
`;

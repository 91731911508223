import styled from 'styled-components';
import theme from '../../configs/theme';

export const HeaderStyles = styled.div`
  background: transparent;
  position: fixed;
  z-index: 998;
  left: 0;
  right: 0;
  margin: auto;
  .hiddenCheckbox {
    display: none;
  }

  .mainHeader {
    background: #fff;
    transition: all 0.5s linear;
    display: flex;

    .header-container {
      
      margin: auto;
    }
  }

  #headerTracker:checked ~ .ant-layout-header {
    background: white;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .menuItem {
    color: #242e33;
    padding: 0px 20px;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0.5px;
    &.active {
      color: red;
    }
  }

  .menuIcon {
    display: none;
    cursor: pointer;
    width: 30px;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 768px) {
    .desktopMenu {
      display: none;
    }
    .menuIcon {
      display: block;
    }
    .ant-layout-header {
      padding: 0px 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .ant-layout-header {
      padding: 0px;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-layout-header {
      padding: 0px;
    }
  }
`;

export const MobileStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 14px;
  .menuItem {
    color: ${theme.text.secondary};
    letter-spacing: 0.2px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 24px;
    &.active {
      color: red;
    }
  }

  .menuIcon {
    display: none;
    cursor: pointer;
    width: 30px;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 768px) {
    .desktopMenu {
      display: none;
    }
    .menuIcon {
      display: block;
    }
  }
  @media only screen and (max-width: 576px) {
  }
`;

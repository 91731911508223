const theme = {
  palette: {
    primary: '#29ABE2',
    lightPrimary: '#fdf6e0',
    light: '#fff6dd',
    secondary: 'rgba(45, 48, 71, 1)',
    loadingBackgroundColor: '#2c3e51cc',
    color: ['#788195', '#E4E6E9'],
  },
  fonts: {
    primary: 'QuickSand',
  },
  background: {
    primary: '#29ABE2',
    lightPrimary: '#fffef9',
    container: '#F4F4F4',
    light: '#FFFEF9',
    content: '#FFFEF8',
    input: '#efeff0',
    disabled: '#969696',
    headerTable: '#d4d2f450',
    blur: '#00000060',
    white: '#fff',
    btnYellow: '#fbde43',
    highlight: '#FDF2D3',
    profileYellow: '#fdf1d2',
    card: '#f4f4f4',
  },
  text: {
    primary: '#242E33',
    text: '#1f2933',
    lightPrimary: '#3e4c59',
    secondary: '#1B1B1B',
    tabTitle: '#262626',
    empty: '#969696',
    highlight: '#5d4ec2',
    disabled: '#969696',
    formLabel: '#4a4a4a',
    headerTable: '#1f2933',
    textBlack: '#000',
    textGray: '#616161',
    color: 'black',
  },
  border: {
    default: '#d4d2f450',
    light: '#e4e7eb',
    gray: '#a7a7a7',
    yellow: '#fbde43',
    lightGray: '#eeeeee',
  },
  scrollbar: {
    thumb: '#b7b6c2',
    track: '#fff',
  },
  footer: {
    background: '#4c4c4c',
    text: 'white',
  },
};

module.exports = theme;
